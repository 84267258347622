import React from 'react';
import {Box} from '@latitude/box';
import StickyCta from '@latitude/sticky-cta/StickyCta';
import {Header} from 'basis';
import { BREAKPOINT, COLOR} from '@/utils/constants';
import {
  resetToDefault
} from '@/components/EstimateRateWidget/EstimateRateWidgetSection';
import HeroBranded from '@/components/HeroBranded/HeroBranded';
import Lframe from '@/components/Lframe/Lframe';
import ConfettiFramedContainer from '@/components/ConfettiFramedContainer/ConfettiFramedContainer';
import EstimateRateWidget from '@/components/EstimateRateWidget/EstimateRateWidget';
import {productGem, SoftQuoteLayout} from '@/components/PersonalLoanSoftQuote/SoftQuoteLayout';
import {localRedirectUrl} from '@/pages/soft-quote-widget/latitude-pl';

export const GemPLContent = (props) => {
  return (
    <main className="navigation-spacer">
      <Box backgroundColor={COLOR.GREY6}>
        {props.withLframe
          && <Lframe personalLoans />
        }
        <HeroBranded
          className="hero-control"
          css={`
              background: ${COLOR.GREY6};
              overflow: hidden;
              @media (max-width: ${BREAKPOINT.MD}) {
                .HeroContent {
                  background-color: ${COLOR.WHITE};
                }
              }
              @media (min-width: ${BREAKPOINT.MD}) and (max-width: ${BREAKPOINT.LG}) {
                .HeroContent {
                  padding-top: 130px;
                }
              }
            `}
          imageContent={
            <div
              css={`
                  height: 100%;
                  display: flex;
                  align-items: flex-end;
                  justify-content: center;
                  @media (min-width: ${BREAKPOINT.LG}) {
                    justify-content: flex-start;
                  }
                `}
            >
              <img
                src={require('../../images/hero/personal-loan@3x.png')}
                alt=""
                css={`
                    height: 250px;
                    width: auto;
                    @media (min-width: ${BREAKPOINT.MD}) {
                      height: 300px;
                    }
                    @media (min-width: ${BREAKPOINT.LG}) {
                      height: 395px;
                    }
                  `}
              />
            </div>
          }
          title={<>Personal Loan</>}
          text={
            <>
              Our personal loans are tailored to suit you, only you, to take
              you where you want to be. Whether it’s money for a renovation,
              holiday or for consolidating debt, check the rates below and
              let’s do this.
            </>
          }
        />
        <div className="d-lg-none d-xl-none">
          <StickyCta
            href="/loans/apply"
            text="Get started"
            trackId="sticky-cta--apply-now"
            phoneNumber="13 28 26"
          />
        </div>
        <div
          className="d-none d-lg-block"
          css="position:relative; z-index:2;"
        />
        <div
          id="check-your-rate"
          css={`
        position: relative;
        z-index: 1;
        & > div {
          @media (max-width: ${BREAKPOINT.LG}) {
            padding: 16px 16px 0;
            margin-bottom: -26px;
          }
          @media (min-width: ${BREAKPOINT.LG}) {
            padding-bottom: 38px;
          }
        }
      `}
        >
          <ConfettiFramedContainer>
            <EstimateRateWidget
              getRate={() => {
                if (typeof window !== 'undefined') {
                  window.location = localRedirectUrl('gem');
                }
              }}
              isGem
              main
            />
          </ConfettiFramedContainer>
        </div>
      </Box>
    </main>
  )
}
const GemPL = (props) => {
  resetToDefault(false, 'purpose', true);
  return (
    <SoftQuoteLayout location={props.location}>
      <Header>
        <Header.Logo name={productGem} />
      </Header>
      <GemPLContent props={props} withLframe/>
    </SoftQuoteLayout>
  );
};

export default GemPL;
