import React, {useState} from 'react';
import { NonFormLayout } from '@/components/PersonalLoanSoftQuote/NonFormLayout';
import CurrencyInput from '@/components/CurrencyInput/CurrencyInput';
import Input from '@/components/Input/Input';
import {COLOR} from '@/utils/constants';
import Select from '@/components/Select/Select';
import ApprovedMessage from '@/components/PersonalLoanSoftQuote/ApprovedMessage';
import ReferredMessage from '@/components/PersonalLoanSoftQuote/ReferredMessage';
import {repaymentPeriodOptions} from '@/components/EstimateRateWidget/MainRateWidget';

// This class is for testing purposes only. Will be removed before production
function RateYourQuoteCalculator({ state, queryString }) {
  //TODO: Remove fallbacks of quoteStatus and interestRate when SF is fully set up.
  const quoteStatus = state.formValues.quoteStatus
    ? state.formValues.quoteStatus
    : 'Quote Initial';
  // TODO: revert this
  // const interestRate = state.formValues.quoteRate
  //   ? state.formValues.quoteRate
  //   : '8.99';
  const quoteNumber = state.formValues.quoteNumber;
  const name = state.formValues.firstName;
  const encryptedQuoteID = state.formValues.encryptedQuoteID;

  // TODO: remove this this
  const [interestRate, setInterestRate] = useState('8.99');
  const [customLoanAmount, setCustomLoanAmount] = useState('3000');
  const [customRepaymentPeriod, setCustomRepaymentPeriod] = useState('5');

  return (
    // John Murfett September 2021: This component seems to be un-used. It has no default export, and a full text search of the code finds 
    // `RateYourQuoteCalculator` finds it only in the soft-quote-user-interface page, which is for interal use only.
    <>

        <NonFormLayout>
          <CurrencyInput
            widget
            labelText='Customised loan amount'
            name="currency-input-story"
            maxLength={10}
            prefix="$"
            value={customLoanAmount}
            onValueChange={e => {
              sessionStorage.setItem('loanAmount', e.value);
              setCustomLoanAmount(e.value);
            }}
          />
          <Select
            widget
            color={COLOR.BLUE}
            label="Over a period of"
            selectedValue={customRepaymentPeriod}
            values={repaymentPeriodOptions}
            onChange={e => {
              sessionStorage.setItem('repaymentPeriod', e.target.value);
              setCustomRepaymentPeriod(e.target.value);
            }}
          />
          <Input
            widget
            id="other-purpose"
            label="Interest rate"
            maxLength="20"
            value={interestRate}
            onChange={e => {
              setInterestRate(e.target.value);
            }}
            type="text"
          />
          <ApprovedMessage
            interestRate={interestRate}
            quoteNumber={quoteNumber}
            encryptedQuoteID={encryptedQuoteID}
            name={name}
            queryString={queryString}
          />
        </NonFormLayout>

      {quoteStatus === 'Referred' &&
        <NonFormLayout>
           <ReferredMessage name={name} />
        </NonFormLayout>
      }
    </>
  );
}
export { RateYourQuoteCalculator };
