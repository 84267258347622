import React from 'react';
import {Heading2} from '@/components/Heading/Heading';
import {ALIGN} from '@/utils/constants';
import Layout from '@/components/layout';
import Metadata from '@/components/Metadata/Metadata';

const KiwiIframe = (props) => {
  return (
    <Layout location={props.location} noHeader noFooter>
      <style
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: `
            html, body, #___gatsby, #___gatsby > div { 
              width: 100%; height: 100%; background-color: #F8F8F8; 
            }
          `
        }}
      />
      <Metadata title="widget embed example" robots="noindex"/>
      <Heading2 align={ALIGN.CENTER} marginTop="40px">
        KiwiBank iframe embed
      </Heading2>
      <div>
        <div style={{
          backgroundImage: `url('https://media.kiwibank.co.nz/media/images/couple_gardening_watering_plants_.2e16d0ba.fill-1536x620.jpg')`,
          width: '1477px',
          height: '596px'
        }}>Kiwi bank Personal Loan</div>
        <div>
          <iframe
            src="https://kiwi-frame.staging.quote-latitudefinancial.digitalweb-np.lfscnp.com/soft-quote-widget/widget-iframe/"
            title="Widget" style={{width: '100%', height: '600px'}}/>

          {/*<iframe*/}
          {/*  src="https://release-pl-softquote.staging.quote-latitudefinancial.digitalweb-np.lfscnp.com/soft-quote-widget"*/}
          {/*  title="Widget" style={{width: '100%', height: '600px'}}></iframe>*/}

          {/*<iframe*/}
          {/*  src="/soft-quote-widget/widget-iframe"*/}
          {/*  title="Widget" style={{width: '100%', height: '600px'}}/>*/}
        </div>

        <div style={{
          margin: '0 auto',
          maxWidth: '960px',
          textAlign: 'center',
          fontFamily: 'Aldaot,Georgia,serif',
          fontVariantNumeric: 'lining-nums'
        }}>
          <p>If don’t have the cash you need help to make a big purchase or you want to bundle a few smaller debts into
            one loan, a personal loan could help.</p>
          <div style={{
            display: 'block',
            height: '4px',
            width: '50px',
            backgroundColor: '#6c3',
            margin: '35px auto 0'
          }}
          />
          <div>
            <h2
              style={{
                fontSize: '40px',
                lineHeight: '1.05',
                letterSpacing: '-.4px'
              }}
            >Key features and benefits</h2><p className="u-text-center">Our standard
            personal loan interest rates range from <span data-rate="644">16.95% p.a.</span> to <span data-rate="645">18.95% p.a.</span>
          </p>
          </div>
        </div>

      </div>

    </Layout>
  );
};

export default KiwiIframe;
