import React from 'react';
import styled from 'styled-components';
import { Link } from '@latitude/link';
import {Box} from '@latitude/box';
import StickyCta from '@latitude/sticky-cta/StickyCta';
import {BREAKPOINT} from '@latitude/core/utils/constants';
import {COLOR, FONT_SIZE, FONT_WEIGHT, MARGIN, SOFT_QUOTE_BROKER_URL} from '@/utils/constants';
import Layout from '@/components/layout';
import {
  resetToDefault
} from '@/components/EstimateRateWidget/EstimateRateWidgetSection';
import HeroBranded from '@/components/HeroBranded/HeroBranded';
import {getSalesforceBrokerUrl, getUrlFromEnv} from '@/utils/getUrlFromEnvUtil';
import ConfettiFramedContainer from '@/components/ConfettiFramedContainer/ConfettiFramedContainer';
import EstimateRateWidget from '@/components/EstimateRateWidget/EstimateRateWidget';
import Lframe from '@/components/Lframe/Lframe';
import {localRedirectUrl} from '@/pages/soft-quote-widget/latitude-pl';

const StyledLink = styled(Link)`
  border-bottom: none;
  font-weight: ${FONT_WEIGHT.NORMAL};
  font-size: ${FONT_SIZE.LARGE};
`;

const HeroLink = styled.div`
  margin-bottom: ${MARGIN.M16};
`;

export const BrokersPLContent = (props) => {
  return (
    <main className="navigation-spacer">
      <Box backgroundColor={COLOR.GREY6}>
        {props.withLframe &&
          <Lframe brokers />
        }
        <HeroBranded
          title='Brokers'
          text="Let’s partner together to grow your business and drive better outcomes for your clients."
          footnote={[
            <HeroLink key="hero-1">
              <StyledLink
                href={SOFT_QUOTE_BROKER_URL}
                trackEventData={{
                  label: 'Get Personal Loan rate and submit application'
                }}
              >
                Get Personal Loan rate and submit application<sup>*</sup>
              </StyledLink>
            </HeroLink>,
            <HeroLink key="hero-2">
              <StyledLink
                href={getSalesforceBrokerUrl()}
                trackEventData={{
                  label: 'Retrieve Personal Loan rate and submit application'
                }}
              >
                Retrieve Personal Loan rate and submit application<sup>*</sup>
              </StyledLink>
            </HeroLink>,
            <HeroLink key="hero-3">
              <StyledLink
                href={getUrlFromEnv('funnel-start-asset-loan')}
                trackEventData={{
                  label: 'Submit Asset Loan Application'
                }}
              >
                Submit Asset Loan application<sup>*</sup>
              </StyledLink>
            </HeroLink>,
            <div key="hero-4">
              <sup>*</sup>Accredited broker use only
            </div>
          ]}
          buttonProps={{
            href: "https://au.docusign.net/Member/PowerFormSigning.aspx?PowerFormId=9fd2e914-084c-4df5-a85b-c9d1417ad2b6&env=au&acct=dbb2995f-5629-43a2-87d7-c96316584d0b&v=2",
            trackId: 'hero-get-accredited',
            children: 'Get Accredited'
          }}
        />
        <div className="d-lg-none d-xl-none">
          <StickyCta
            href="/loans/apply"
            text="Get started"
            trackId="sticky-cta--apply-now"
            phoneNumber="13 28 26"
          />
        </div>
        <div
          className="d-none d-lg-block"
          css="position:relative; z-index:2;"
        />
        <div
          id="check-your-rate"
          css={`
        position: relative;
        z-index: 1;
        & > div {
          @media (max-width: ${BREAKPOINT.LG}) {
            padding: 16px 16px 0;
            margin-bottom: -26px;
          }
          @media (min-width: ${BREAKPOINT.LG}) {
            padding-bottom: 38px;
          }
        }
      `}
        >
          <ConfettiFramedContainer>
            <EstimateRateWidget
              getRate={() => {
                if (typeof window !== 'undefined') {
                  window.location = localRedirectUrl('broker');
                }
              }}
              broker
              main
            />
          </ConfettiFramedContainer>
        </div>
      </Box>
    </main>
  )
}
const BrokersPL = (props) => {
  resetToDefault();
  return (
    <Layout location={props.location}>
      <BrokersPLContent props={props} withLframe/>
    </Layout>
  );
};

export default BrokersPL;
