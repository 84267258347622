/* eslint-disable no-console */
// TODO - this page is for development only. please remove for production

import React, { useState } from 'react';
import styled from 'styled-components';
import Layout from '@/components/layout';
import { AboutYou } from '@/components/PersonalLoanSoftQuote/pages-au/PersonalLoanSoftQuoteAboutYou';
import { Employment } from '@/components/PersonalLoanSoftQuote/pages-au/PersonalLoanSoftQuoteEmployment';
import ReferredMessage from '@/components/PersonalLoanSoftQuote/ReferredMessage';
import { NonFormLayout } from '@/components/PersonalLoanSoftQuote/NonFormLayout';
import { ServerError } from '@/components/PersonalLoanSoftQuote/ServerError';
import { emptyFormValues } from '@/components/PersonalLoanSoftQuote/pages-au/PersonalLoanSoftQuoteReducer';
import { emptyFormValues as emptyFormValuesBroker } from '@/components/PersonalLoanSoftQuote/pages-broker/PersonalLoanSoftQuoteReducerBroker';
import { emptyFormValues as emptyFormValuesGem } from '@/components/PersonalLoanSoftQuote/pages-gem/PersonalLoanSoftQuoteReducerGem';
import {
  QuoteExpiredSection,
  ApplicationInProgressSection,
  SectionLayout
} from '@/components/PersonalLoanSoftQuote/retrieve/RetrieveQuote';
import { SimplifiedFooter } from '@/components/PersonalLoanSoftQuote/SimplifiedFooter';
import { BrokerDetails } from '@/components/PersonalLoanSoftQuote/pages-broker/BrokerDetails';
import { BrokerAboutYou } from '@/components/PersonalLoanSoftQuote/pages-broker/BrokerAboutYou';
import { BrokerEmployment } from '@/components/PersonalLoanSoftQuote/pages-broker/BrokerEmployment';
import { BrokerYourQuote } from '@/components/PersonalLoanSoftQuote/pages-broker/BrokerYourQuote';
import { GemAboutYou } from '@/components/PersonalLoanSoftQuote/pages-gem/GemAboutYou';
import { GemEmployment } from '@/components/PersonalLoanSoftQuote/pages-gem/GemEmployment';
import { GemYourQuote } from '@/components/PersonalLoanSoftQuote/pages-gem/GemYourQuote';
import { resetToDefault } from '@/components/EstimateRateWidget/EstimateRateWidgetSection';
import KiwiIframe from '@/pages/soft-quote-widget/kiwi-pl';
import { PLContent } from '@/pages/soft-quote-widget/latitude-pl';
import { BrokersPLContent } from '@/pages/soft-quote-widget/brokers-pl';
import { GemPLContent } from '@/pages/soft-quote-widget/gem-pl';
import {
  LOAN_PURPOSE,
  LOAN_PURPOSE_AU,
  SALESFORCE_STATUS
} from '@/components/PersonalLoanSoftQuote/constants/softQuoteConstants';
import { RetrieveQuoteSection } from '@/components/PersonalLoanSoftQuote/retrieve/RetrieveQuoteSection';
import { VerifySmsSection } from '@/components/PersonalLoanSoftQuote/retrieve/VerifySmsSection';
import { QuoteDeclinedSection } from '@/components/PersonalLoanSoftQuote/QuoteDeclinedSection';
import { GemRateYourQuoteCalculator } from '@/components/PersonalLoanSoftQuote/pages-gem/GemRateYourQuoteCalculator';
import { RateYourQuoteCalculator } from '@/components/PersonalLoanSoftQuote/pages-au/RateYourQuoteCalculator';
import {
  SoftQuoteLayout,
  productGem
} from '../../components/PersonalLoanSoftQuote/SoftQuoteLayout';
import { SoftQuoteHeader } from '../../components/PersonalLoanSoftQuote/Header';
import { YourQuote } from '../../components/PersonalLoanSoftQuote/pages-au/PersonalLoanSoftQuoteYourQuote';
import { BREAKPOINT } from '../../utils/constants';

const initialUrlQueryString =
  typeof window !== 'undefined' && new URLSearchParams(window.location.search);
const initialUrlQueryStringSection =
  typeof window !== 'undefined' && initialUrlQueryString.get('section');

export default props => {
  const [section, setSection] = useState(initialUrlQueryStringSection);
  const [navActive, setNavActive] = useState(!initialUrlQueryStringSection);

  if (typeof window !== 'undefined') {
    sessionStorage.setItem('purpose', 'car-purchase');
  }

  const ShowPagesButton = styled.button`
    display: ${props => (props.active ? 'none' : 'block')};
    width: 80px;
    height: 50px;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1001;
    font-size: 12px;
    background-color: rgba(221, 221, 221, 0.5);
    border-radius: 5px;
  `;

  const OLWrapper = styled.div`
    ol {
      display: ${props => (props.active ? 'block' : 'none')};
      position: absolute;
      columns: 2;
      z-index: 1000;
      padding: 16px 16px 16px 32px;
      margin: 0;
      background-color: #fff;

      @media (min-width: ${BREAKPOINT.MD}) {
        columns: 5;
      }
    }
  `;

  return (
    <Layout location={props.location} noHeader noFooter>
      <SoftQuoteLayout>
        <OLWrapper
          onClick={() => {
            if (section) {
              setSection(false);
              setNavActive(true);
              var urlQueryString =
                typeof window !== 'undefined' &&
                new URLSearchParams(window.location.search);
              urlQueryString.delete('section');
              const newQueryString =
                urlQueryString && urlQueryString.toString();
              var newRelativePathQuery =
                typeof window !== 'undefined' &&
                window.location.pathname +
                  (newQueryString ? `?${newQueryString}` : '');
              typeof history !== 'undefined' &&
                history.pushState(null, '', newRelativePathQuery);
            } else {
              setNavActive(false);
            }
          }}
          active={navActive}
        >
          <ShowPagesButton active={navActive}>show pages</ShowPagesButton>

          {navActive && (
            <ol>
              {[
                '----AU Online----',
                'PL-Page',
                'about-you',
                'employment',
                'your-quote',
                'your-quote-better-start',
                'your-quote-calculator',
                'your-quote-initial-submit',
                'your-quote-service-unavailable',
                'retrieve',
                'retrieve-duplicate',
                'retrieve-verify',
                'retrieve-converted-to-application',
                'expired',
                'declined',
                'referred',
                'server-error',
                '----BROKER----',
                'Brokers-Page',
                'broker-details',
                'broker-about-you',
                'broker-about-you-better-start',
                'broker-employment',
                'broker-your-quote',
                'broker-your-quote-better-start',
                'broker-your-quote-initial-submit',
                'broker-your-quote-service-unavailable',
                'broker-declined',
                'broker-referred',
                'broker-server-error',
                '----GEM----',
                'GEM-Page',
                'gem-about-you',
                'gem-employment',
                'gem-your-quote-auto-loan',
                'gem-your-quote-non-auto-loan',
                'gem-your-quote-calculator',
                'gem-your-quote-initial-submit',
                'gem-your-quote-service-unavailable',
                'gem-retrieve',
                'gem-retrieve-duplicate',
                'gem-retrieve-verify',
                'gem-retrieve-converted-to-application',
                'gem-expired',
                'gem-declined',
                'gem-referred',
                'gem-server-error',
                '----Kiwi----',
                'kiwi-iframe'
              ].map(item => (
                <li key={item}>
                  <a
                    onClick={e => {
                      setSection(item);
                      var urlQueryString =
                        typeof window !== 'undefined' &&
                        new URLSearchParams(window.location.search);
                      urlQueryString && urlQueryString.set('section', item);
                      var newRelativePathQuery =
                        typeof window !== 'undefined' &&
                        window.location.pathname +
                          '?' +
                          urlQueryString.toString();
                      typeof history !== 'undefined' &&
                        history.pushState(null, '', newRelativePathQuery);
                      e.preventDefault();
                    }}
                    href="##"
                  >
                    {item}
                  </a>
                </li>
              ))}
            </ol>
          )}
        </OLWrapper>
        {!navActive && (
          <>
            {section == 'PL-Page' && (
              <>
                {resetToDefault()}
                <PLContent location={location} />
              </>
            )}
            {section == 'about-you' && (
              <>
                {resetToDefault()}
                <AboutYou state={{ formValues: emptyFormValues }} />
              </>
            )}

            {section == 'employment' && (
              <>
                {resetToDefault(false, 'car-purchase')}
                <Employment
                  state={{ formValues: emptyFormValues }}
                  dispatch={() => {}}
                />
              </>
            )}

            {section == 'your-quote' && (
              <>
                {resetToDefault(false, 'car-purchase')}
                <YourQuote
                  state={{
                    formValues: {
                      ...emptyFormValues,
                      firstName: 'Alex',
                      quoteNumber: 'Q2020060995',
                      quoteStatus: SALESFORCE_STATUS.QuoteOffered,
                      quoteRate: '8.99',
                      quoteSecuredRate: '9.99'
                    }
                  }}
                  dispatch={() => {}}
                  queryString={props.location.search}
                />
              </>
            )}
            {section == 'your-quote-better-start' && (
              <>
                {resetToDefault(false, LOAN_PURPOSE_AU.DEBT_CONSOLIDATION)}
                <YourQuote
                  state={{
                    formValues: {
                      ...emptyFormValues,
                      firstName: 'Alex',
                      quoteNumber: 'Q2020060995',
                      quoteStatus: SALESFORCE_STATUS.QuoteOffered,
                      quoteRate: '8.99',
                      quoteSecuredRate: '9.99'
                    }
                  }}
                  dispatch={() => {}}
                  queryString={props.location.search}
                  isBetterStart
                />
              </>
            )}
            {section == 'your-quote-calculator' && (
              <>
                {resetToDefault(false, 'car-purchase')}
                <RateYourQuoteCalculator
                  state={{
                    formValues: {
                      ...emptyFormValues,
                      firstName: 'Alex',
                      quoteNumber: 'Q2020060995',
                      quoteStatus: SALESFORCE_STATUS.QuoteOffered,
                      quoteRate: '8.99'
                    }
                  }}
                  dispatch={() => {}}
                  queryString={props.location.search}
                />
              </>
            )}
            {section == 'your-quote-initial-submit' && (
              <>
                {resetToDefault(false, 'car-purchase')}
                <YourQuote
                  state={{
                    formValues: {
                      ...emptyFormValues,
                      firstName: 'Alex',
                      quoteNumber: 'Q2020060995',
                      quoteStatus: SALESFORCE_STATUS.InitialSubmit,
                      quoteRate: null
                    }
                  }}
                  dispatch={() => {}}
                  queryString={props.location.search}
                />
              </>
            )}
            {section == 'your-quote-service-unavailable' && (
              <>
                {resetToDefault(false, 'car-purchase')}
                <YourQuote
                  state={{
                    formValues: {
                      ...emptyFormValues,
                      firstName: 'Alex',
                      quoteNumber: 'Q2020060995',
                      quoteStatus: SALESFORCE_STATUS.ServiceUnavailable,
                      quoteRate: null
                    }
                  }}
                  dispatch={() => {}}
                  queryString={props.location.search}
                />
              </>
            )}
            {section == 'retrieve' && (
              <>
                <SoftQuoteHeader />
                <SectionLayout>
                  <RetrieveQuoteSection
                    queryString={props.location.search}
                    dispatch={{ setUserData: () => {} }}
                    showLoadingOverlay={() => {}}
                  />
                </SectionLayout>
                <SimplifiedFooter />
              </>
            )}

            {section == 'retrieve-duplicate' && (
              <>
                <SoftQuoteHeader />
                <SectionLayout>
                  <RetrieveQuoteSection
                    queryString="?duplicate-check"
                    dispatch={{ setUserData: () => {} }}
                  />
                </SectionLayout>
                <SimplifiedFooter />
              </>
            )}

            {section == 'retrieve-verify' && (
              <>
                <SoftQuoteHeader />
                <SectionLayout>
                  <VerifySmsSection
                    userData={{ smsCode: 123456, phoneNumber: '0412345678' }}
                    dispatch={{ setUserData: () => {} }}
                  />
                </SectionLayout>
                <SimplifiedFooter />
              </>
            )}

            {section == 'retrieve-converted-to-application' && (
              <>
                {resetToDefault(false, LOAN_PURPOSE.EDUCATION, true)}
                <SoftQuoteHeader />
                <SectionLayout>
                  <ApplicationInProgressSection
                    queryString="?duplicate-check"
                    dispatch={{ setUserData: () => {} }}
                  />
                </SectionLayout>
                <SimplifiedFooter />
              </>
            )}

            {section == 'expired' && (
              <>
                <SoftQuoteHeader />
                <SectionLayout>
                  <QuoteExpiredSection />
                </SectionLayout>
                <SimplifiedFooter />
              </>
            )}

            {section == 'declined' && (
              <>
                <SoftQuoteHeader />
                {/* <DeclinedMessage /> */}
                <SectionLayout>
                  <QuoteDeclinedSection userData={{ firstName: 'Alex' }} />
                </SectionLayout>
                <SimplifiedFooter />
              </>
            )}

            {section == 'referred' && (
              <NonFormLayout>
                <ReferredMessage name="Alex" />
              </NonFormLayout>
            )}
            {section == 'broker-referred' && (
              <NonFormLayout>
                <ReferredMessage name="Alex" broker />
              </NonFormLayout>
            )}

            {section == 'server-error' && (
              <>
                <SoftQuoteHeader />
                <SectionLayout>
                  <ServerError />
                </SectionLayout>
                <SimplifiedFooter />
              </>
            )}

            {section == 'Brokers-Page' && (
              <>
                {resetToDefault(true)}
                <BrokersPLContent location={location} withLframe={false} />
              </>
            )}
            {section == 'broker-details' && (
              <BrokerDetails
                state={{ formValues: emptyFormValuesBroker }}
                dispatch={() => {}}
              />
            )}
            {section == 'broker-about-you' && (
              <>
                {resetToDefault(true, 'car-purchase')}
                <BrokerAboutYou
                  state={{ formValues: emptyFormValuesBroker }}
                  dispatch={() => {}}
                />
              </>
            )}
            {section == 'broker-about-you-better-start' && (
              <>
                {resetToDefault(true, LOAN_PURPOSE_AU.DEBT_CONSOLIDATION)}
                <BrokerAboutYou
                  state={{ formValues: emptyFormValuesBroker }}
                  dispatch={() => {}}
                  isBroker
                  isBetterStart
                />
              </>
            )}
            {section == 'broker-employment' && (
              <>
                {resetToDefault(true, 'car-purchase')}
                <BrokerEmployment
                  state={{ formValues: emptyFormValuesBroker }}
                  dispatch={() => {}}
                />
              </>
            )}

            {section == 'broker-your-quote' && (
              <>
                {resetToDefault(true, 'car-purchase')}
                <BrokerYourQuote
                  state={{
                    formValues: {
                      ...emptyFormValues,
                      firstName: 'Alex',
                      quoteNumber: 'Q2020060995',
                      brokerFee: '100',
                      quoteStatus: SALESFORCE_STATUS.QuoteOffered,
                      quoteRate: '8.99',
                      quoteSecuredRate: '9.99'
                    }
                  }}
                  dispatch={() => {}}
                  queryString={props.location.search}
                />
              </>
            )}
            {section == 'broker-your-quote-better-start' && (
              <>
                {resetToDefault(true, 'car-purchase')}
                <BrokerYourQuote
                  state={{
                    formValues: {
                      ...emptyFormValues,
                      firstName: 'Alex',
                      quoteNumber: 'Q2020060995',
                      brokerFee: '100',
                      quoteStatus: SALESFORCE_STATUS.QuoteOffered,
                      quoteRate: '8.99',
                      quoteSecuredRate: '9.99'
                    }
                  }}
                  dispatch={() => {}}
                  queryString={props.location.search}
                  isBetterStart
                />
              </>
            )}
            {section == 'broker-your-quote-initial-submit' && (
              <>
                {resetToDefault(true, 'car-purchase')}
                <BrokerYourQuote
                  state={{
                    formValues: {
                      ...emptyFormValues,
                      firstName: 'Alex',
                      quoteNumber: 'Q2020060995',
                      brokerFee: '100',
                      quoteStatus: SALESFORCE_STATUS.InitialSubmit,
                      quoteRate: null
                    }
                  }}
                  dispatch={() => {}}
                  queryString={props.location.search}
                />
              </>
            )}
            {section == 'broker-your-quote-service-unavailable' && (
              <>
                {resetToDefault(true, 'car-purchase')}
                <BrokerYourQuote
                  state={{
                    formValues: {
                      ...emptyFormValues,
                      firstName: 'Alex',
                      quoteNumber: 'Q2020060995',
                      brokerFee: '100',
                      quoteStatus: SALESFORCE_STATUS.ServiceUnavailable,
                      quoteRate: null
                    }
                  }}
                  dispatch={() => {}}
                  queryString={props.location.search}
                />
              </>
            )}
            {section == 'broker-declined' && (
              <>
                <SoftQuoteHeader />
                <SectionLayout>
                  <QuoteDeclinedSection
                    userData={{ firstName: 'Alex' }}
                    broker
                  />
                </SectionLayout>
                <SimplifiedFooter />
              </>
            )}

            {section == 'referred-broker' && (
              <NonFormLayout>
                <ReferredMessage name="Alex" broker />
              </NonFormLayout>
            )}

            {section == 'broker-server-error' && (
              <>
                <SoftQuoteHeader />
                <SectionLayout>
                  <ServerError hideTryAgain broker />
                </SectionLayout>
                <SimplifiedFooter />
              </>
            )}

            {section == 'GEM-Page' && (
              <>
                {resetToDefault(false, 'purpose', true)}
                <GemPLContent location={location} withLframe={false} />
              </>
            )}

            {section == 'gem-about-you' && (
              <GemAboutYou state={{ formValues: emptyFormValuesGem }} />
            )}

            {section == 'gem-employment' && (
              <>
                {resetToDefault(false, LOAN_PURPOSE.AUTO_PURCHASE, true)}
                <GemEmployment
                  state={{ formValues: emptyFormValuesGem }}
                  dispatch={() => {}}
                />
              </>
            )}

            {section == 'gem-your-quote-auto-loan' && (
              <>
                {resetToDefault(false, LOAN_PURPOSE.AUTO_PURCHASE, true)}
                <GemYourQuote
                  state={{
                    formValues: {
                      ...emptyFormValues,
                      firstName: 'Alex',
                      quoteNumber: 'Q2020060995',
                      quoteStatus: SALESFORCE_STATUS.QuoteOffered,
                      quoteRate: '8.99',
                      quoteSecuredRate: '9.99'
                    }
                  }}
                  dispatch={() => {}}
                  queryString={props.location.search}
                  showLoadingOverlay={() => {}}
                  isGem
                />
              </>
            )}

            {section == 'gem-retrieve' && (
              <>
                <SoftQuoteHeader product={productGem} />
                <SectionLayout>
                  <RetrieveQuoteSection isGem queryString="" />
                </SectionLayout>
                <SimplifiedFooter product={productGem} />
              </>
            )}

            {section == 'gem-retrieve-duplicate' && (
              <>
                <SoftQuoteHeader product={productGem} />
                <SectionLayout>
                  <RetrieveQuoteSection
                    queryString="?duplicate-check"
                    dispatch={{ setUserData: () => {} }}
                    isGem
                  />
                </SectionLayout>
                <SimplifiedFooter product={productGem} />
              </>
            )}

            {section == 'gem-retrieve-converted-to-application' && (
              <>
                {resetToDefault(false, LOAN_PURPOSE.EDUCATION, true)}
                <SoftQuoteHeader product={productGem} />
                <SectionLayout>
                  <ApplicationInProgressSection
                    queryString="?duplicate-check"
                    dispatch={{ setUserData: () => {} }}
                    isGem
                  />
                </SectionLayout>
                <SimplifiedFooter product={productGem} />
              </>
            )}

            {section == 'gem-your-quote-non-auto-loan' && (
              <>
                {resetToDefault(false, LOAN_PURPOSE.EDUCATION, true)}
                <GemYourQuote
                  state={{
                    formValues: {
                      ...emptyFormValues,
                      firstName: 'Alex',
                      quoteNumber: 'Q2020060995',
                      quoteStatus: SALESFORCE_STATUS.QuoteOffered,
                      quoteRate: '8.99',
                      quoteSecuredRate: '9.99'
                    }
                  }}
                  dispatch={() => {}}
                  queryString={props.location.search}
                />
              </>
            )}
            {section == 'gem-your-quote-calculator' && (
              <>
                {resetToDefault(false, LOAN_PURPOSE.EDUCATION, true)}
                <GemRateYourQuoteCalculator
                  state={{
                    formValues: {
                      ...emptyFormValues,
                      firstName: 'Alex',
                      quoteNumber: 'Q2020060995',
                      quoteStatus: SALESFORCE_STATUS.QuoteOffered,
                      quoteRate: '8.99'
                    }
                  }}
                  dispatch={() => {}}
                  queryString={props.location.search}
                />
              </>
            )}
            {section == 'gem-your-quote-initial-submit' && (
              <>
                {resetToDefault(false, LOAN_PURPOSE.EDUCATION, true)}
                <GemYourQuote
                  state={{
                    formValues: {
                      ...emptyFormValues,
                      firstName: 'Alex',
                      quoteNumber: 'Q2020060995',
                      quoteStatus: SALESFORCE_STATUS.InitialSubmit,
                      quoteRate: null
                    }
                  }}
                  dispatch={() => {}}
                  queryString={props.location.search}
                />
              </>
            )}
            {section == 'gem-your-quote-service-unavailable' && (
              <>
                {resetToDefault(false, LOAN_PURPOSE.EDUCATION, true)}
                <GemYourQuote
                  state={{
                    formValues: {
                      ...emptyFormValues,
                      firstName: 'Alex',
                      quoteNumber: 'Q2020060995',
                      quoteStatus: SALESFORCE_STATUS.ServiceUnavailable,
                      quoteRate: null
                    }
                  }}
                  dispatch={() => {}}
                  queryString={props.location.search}
                />
              </>
            )}
            {section == 'gem-retrieve-verify' && (
              <>
                <SoftQuoteHeader product={productGem} />
                <SectionLayout>
                  <VerifySmsSection
                    userData={{ smsCode: 123456, phoneNumber: '0412345678' }}
                    dispatch={{ setUserData: () => {} }}
                    isGem
                  />
                </SectionLayout>
                <SimplifiedFooter product={productGem} />
              </>
            )}

            {section == 'gem-expired' && (
              <>
                <SoftQuoteHeader product={productGem} />
                <SectionLayout>
                  <QuoteExpiredSection isGem />
                </SectionLayout>
                <SimplifiedFooter product={productGem} />
              </>
            )}

            {section == 'gem-declined' && (
              <>
                <SoftQuoteHeader product={productGem} />
                <SectionLayout>
                  <QuoteDeclinedSection
                    userData={{ firstName: 'Alex' }}
                    isGem
                  />
                </SectionLayout>
                <SimplifiedFooter product={productGem} />
              </>
            )}

            {section == 'gem-referred' && (
              <NonFormLayout product={productGem}>
                <ReferredMessage name="Alex" isGem />
              </NonFormLayout>
            )}
            {section == 'gem-server-error' && (
              <>
                <SoftQuoteHeader product={productGem} />
                <SectionLayout>
                  <ServerError isGem hideTryAgain />
                </SectionLayout>
                <SimplifiedFooter product={productGem} />
              </>
            )}
            {section == 'kiwi-iframe' && (
              <>
                {resetToDefault()}
                <KiwiIframe location={location} />
              </>
            )}
          </>
        )}
      </SoftQuoteLayout>
    </Layout>
  );
};
